import getColorStyle from 'lib/getColorStyle'
import ifTrue from 'lib/ifTrue'
import Image from 'components/Image'
import cx from 'classnames'
import Reverse from 'components/Reverse'
import { useRef } from 'react'
import useCarousel from 'hooks/useCarousel'
import ChevronButton from 'components/ChevronButton'

export default function IconModule({ module }) {
  const { heading, background, items, bottomLine, subheading } = module
  const { ref, prevProps, nextProps } = useCarousel({
    align: 'start',
  })

  const isGridStyle = items.length <= 4

  const itemsContainerStyles = isGridStyle ? 'max-w-425 l:max-w-full l:w-full grid grid-cols-2 gap-x-4 gap-y-30 m:gap-y-24 m:gap-x-80 l:gap-4 l:flex l:justify-between': 'flex'
  return (
    <div
      className={cx('pl-16 m:pl-24 l:px-60 xl:px-135 py-32 m:py-50 l:py-64 text-center relative overflow-hidden', {
        'pl-26 m:pl-24 pr-26 m:pr-24': isGridStyle
      })}
      style={{ background: getColorStyle(background) }}
    >
      <h2 className='text-24 m:text-32 l:text-40 font-medium mb-16 l:mb-24 pr-16 m:pr-24 l:pr-0'>{heading}</h2>
      {subheading && <p className='text-15 m:text-18 l:text-20 pr-16 m:pr-24 l:pr-0 max-w-640 l:max-w-full mx-auto'>{subheading}</p>}
      <div className='relative'>
        <div className={cx('overflow-hidden m:mt-48 l:mt-40', {
          'flex justify-center mt-22': isGridStyle,
          'mt-32': !isGridStyle
        })} ref={isGridStyle ? null : ref}>
          <ul className={itemsContainerStyles}>
            {items.map((item, i) => {
              const isLastItem = i === items.length - 1;
              const isOddLengthArray = items.length % 2 !== 0;

              return (
                <li
                  style={{ ...(isLastItem && isOddLengthArray && isGridStyle ? { 'grid-column': 'span 2' } : {}) }}
                  className={cx('min-w-47p  m:min-w-35p l:min-w-25p px-8 xl:px-47',
                    {
                      'm:px-30  m:mb-26': !isGridStyle,
                      'flex justify-center m:mb-0': isGridStyle
                    }
                  )}
                  key={item.key}
                >
                  <div className={cx('l:max-w-full', {
                    'max-w-176': isGridStyle
                  })}>
                    <Reverse if={item.isTextFirst} >
                      <div className={cx('flex items-center m:w-104 w-72 h-72 m:h-104 mb-12 m:mb-18 mx-auto')}>
                        <Image
                          placeholder={false}
                          image={item.image}
                          alt=''
                        />
                      </div>

                      <div>
                        <div className='font-medium text-13 m:text-16 mb-8 l:mb-16 leading-13 m:leading-16'>{item.title}</div>
                        <p
                          style={{ ...(isGridStyle ? { maxWidth: '16.5rem' } : {}) }}
                          className='text-13 m:text-16 leading-16 m:leading-20'
                        >{item.description}</p>
                      </div>
                    </Reverse>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
        {!isGridStyle && <div
          style={{ 'background-image': 'radial-gradient(ellipse at right center, white 30%, rgba(255, 255, 255, 0) 70%)' }}
          className={cx(
            'l:hidden h-full w-16 m:w-28 absolute top-0 right-0 -bottom-32 m:-bottom-50 z-2 bg-gradient-to-br from-transparent-white to-white transition pointer-events-none opacity-80'
          )}
        ></div>}
      </div>
      {items?.length > 4 ? (
        <>
          <div
            className="absolute left-0 m:left-40 xl:left-60 transform -translate-y-1/2 hidden l:block"
            style={{ top: '50%' }}
          >
            <ChevronButton className='bg-transparent border-none' chevronIconClassName='w-15' direction="left" label="Previous slide" {...prevProps} />
          </div>
          <div
            className="absolute right-0 m:right-40 xl:right-60 transform -translate-y-1/2 hidden l:block"
            style={{ top: '50%' }}
          >
            <ChevronButton className='bg-transparent border-none' chevronIconClassName='w-15' direction="right" label="Next slide" {...nextProps} />
          </div>
        </>
      ) : null}
      {ifTrue(bottomLine, <p className='mt-36 m:mt-52 text-15 m:text-14 text-navy-blue-muted font-serif italic pr-16 m:pr-24'>{bottomLine}</p>)}
    </div>)
}