import flattenChildren from 'react-keyed-flatten-children'

export default function Reverse(props) {
  const flattened = flattenChildren(props.children)

  if (props.if) {
    flattened.reverse()
  }

  return <>{flattened}</> 
}
